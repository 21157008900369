import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue';
import Resume from '../views/MyResume.vue';
import Note from '../views/NoteTaking.vue';

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/resume', name: 'Resume', component: Resume },
    { path: '/note', name: 'Note', component: Note },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
