<template>
  <nav class="navbar sticky-xxl-top navbar-dark px-sm-5 bg-black">
    <a class="navbar-brand" href="#">Akshat Soni</a>
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link text-light" @click.prevent="scrollToSection('MyWork')" href="">My Work</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-light" @click.prevent="scrollToSection('ContactMe')" href="">Contact Me</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-light" @click.prevent="scrollToSection('SocialMedia')" href="">My Socials</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>

</style>
