<template>
  <div class="d-flex text-center justify-content-center align-items-center vh-100 p-5">
    <div class="container-fluid rounded d-flex flex-column vh-100">
      <div class="row flex-grow-1 d-flex">
        <div class="col-12 rounded mb-3 d-flex flex-column vh-100">
          <h2 class="flex-shrink-0">✏️ Note</h2>
          <div class="flex-grow-1 d-flex flex-column">
            <textarea
                class="w-100 bg-dark text-white rounded flex-grow-1"
                v-model="newNote"
                placeholder="Write your note here... Press CTRL+Enter to save the note"
                @keyup="handleKeyup"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <button @click="addNote" class="btn btn-primary floating-btn-save">
      <i class="bi bi-save"></i>
    </button>
    <button v-if="notes.length" @click="toggleHistoryPopup" class="btn btn-secondary floating-btn-history">
      <i class="bi bi-clock-history"></i>
    </button>

    <div v-if="showHistory" class="popup" @click.self="closeHistoryPopup">
      <div class="popup-content">
        <h2>History</h2>
        <div class="btn-group">
          <button @click="toggleSortOrder" class="btn text-white mb-2 border border-white">
            <i :class="sortAsc ? 'bi bi-sort-down' : 'bi bi-sort-up'"></i>
          </button>
          <button @click="clearHistory" class="btn btn-danger mb-2 ml-2">
            <span class="bi bi-trash3"></span>
          </button>
          <button @click="toggleHistoryPopup" class="btn btn-secondary mb-2 ml-2">
            <span class="bi bi-x"></span>
          </button>
        </div>
        <div class="note-list">
          <div v-for="note in sortedNotes" :key="note.id" class="card mb-3 rounded">
            <div class="card-body bg-dark text-light rounded text-left">
              <p class="card-text" v-html="formatNoteText(note.text)"></p>
              <div class="text-info small text-white d-flex justify-content-between" style="font-size: xx-small">
                <span>{{ formatTimestamp(note.timestamp) }}</span>
                <div>
                  <button @click="restoreNoteToTextarea(note.text, note.id)" class="btn btn-link text-warning p-0 mx-1">
                    <span class="bi bi-arrow-counterclockwise"></span>
                  </button>
                  <button @click="copyNoteToClipboard(note.text)" class="btn btn-link text-info p-0 mx-1">
                    <span class="bi bi-clipboard"></span>
                  </button>
                  <button @click="deleteNoteById(note.id)" class="btn btn-link text-danger p-0 mx-1">
                    <span class="bi bi-trash3"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!notes.length" class="text-white">No notes to display.</div>
        </div>
      </div>
    </div>
    <div v-if="showToast" class="toast show align-items-center text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          Note copied to clipboard!
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="showToast = false"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newNote: '',
      notes: [],
      sortAsc: true,
      showToast: false,
      showHistory: false // History popup visibility
    };
  },
  created() {
    this.loadNotes();
  },
  computed: {
    sortedNotes() {
      if (this.sortAsc) {
        return this.notes.slice().sort((a, b) => a.timestamp - b.timestamp);
      } else {
        return this.notes.slice().sort((a, b) => b.timestamp - a.timestamp);
      }
    }
  },
  methods: {
    addNote() {
      if (this.newNote.trim() !== '') {
        this.notes.push({
          id: Date.now(),
          text: this.newNote.trim(),
          timestamp: new Date().getTime()
        });
        this.newNote = '';
        this.saveNotes();
      }
    },
    deleteNoteById(id) {
      this.notes = this.notes.filter(note => note.id !== id);
      this.saveNotes();
      if (this.notes.length === 0) {
        this.showHistory = false;
      }
    },
    clearHistory() {
      this.notes = [];
      this.saveNotes();
      this.showHistory = false;
    },
    saveNotes() {
      localStorage.setItem('notes', JSON.stringify(this.notes));
    },
    loadNotes() {
      const savedNotes = localStorage.getItem('notes');
      if (savedNotes) {
        this.notes = JSON.parse(savedNotes);
      }
    },
    handleKeyup(event) {
      if (event.ctrlKey && event.key === 'Enter') {
        this.addNote();
      }
    },
    formatNoteText(note) {
      return note.replace(/\n/g, '<br>');
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    toggleSortOrder() {
      this.sortAsc = !this.sortAsc;
    },
    toggleHistoryPopup() {
      this.showHistory = !this.showHistory;
    },
    closeHistoryPopup() {
      this.showHistory = false;
    },
    restoreNoteToTextarea(noteText, id) {
      this.newNote = noteText;
      this.deleteNoteById(id);
      this.showHistory = false;
    },
    copyNoteToClipboard(noteText) {
      const textarea = document.createElement('textarea');
      textarea.value = noteText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.showToastNotification();
    },
    showToastNotification() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
  }
};
</script>

<style scoped>
.floating-btn-save {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floating-btn-history {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
}

.note-list {
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
  list-style-type: none;
  flex-grow: 1;
}

.note-list::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

textarea {
  border-radius: 0.5rem;
  resize: vertical;
  min-height: calc(100vh - 3.5rem); /* Height of textarea adjusted */
  flex-grow: 1;
}

.card {
  border-radius: 0.5rem;
  position: relative;
}

.card-body.text-left {
  text-align: left;
}

@media (max-width: 768px) {
  .container-fluid {
    flex-direction: column;
  }

  .note-list {
    max-height: 300px;
  }

  textarea {
    min-height: calc(100vh - 3.5rem); /* Adjusted for smaller screens */
  }

  .floating-btn-save {
    bottom: 10px;
    right: 10px;
  }

  .floating-btn-history {
    bottom: 70px;
    right: 10px;
  }
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
  min-width: 250px;
}
</style>
