<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/mycss.css';
</style>
