<template>
  <div>
    <iframe id="pdf-container" src="/resume.pdf" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'MyResume',
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#pdf-container {
  width: 100vw;
  height: 100vh;
}
</style>
