<template>
  <div id="home" class="bg-black text-white">
    <NavBar/>
    <section class="page-section" id="AboutMe" >
      <div class="container">
        <div class="row g-5">
          <div class="col d-flex justify-content-center align-items-center">
            <img class="rounded-circle shadow-light blur" src="../assets/images/me.jpg" alt="Akshat Soni"
                 style="height: 300px; width: 300px; object-fit: cover;">
          </div>
          <div class="col ">
            <div class="card-body">
              <h1 class="card-title">Hi 👋, I'm Akshat Soni</h1>
              <p class="card-text">I am a mobile game developer.</p>
              <p class="card-text">I am an extremely curious individual with a great passion for data and AI in games. I
                am a critical thinker and problem solver. I seek a role in a company where I can implement the skills I
                have learned, polish them, and grow professionally; ultimately benefitting the organization that I work
                for.</p>

              <p><i class="bi bi-controller text-secondary"></i> Jr. Unity Developer</p>
              <p><i class="bi bi-mortarboard-fill text-primary"></i> Master's of Applied Computing</p>
              <p><a class="text-white link-underline link-underline-opacity-0" href="https://g.co/kgs/iXKtQx"
                    target="_blank"><i class="bi bi-geo-alt-fill text-danger"></i> Windsor, Ontario, Canada</a></p>
              <p><a class="text-white link-underline link-underline-opacity-0" href="/resume"
                    target="_blank"><i class="bi bi-file-arrow-down-fill text-success"></i> Resume</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="MyWork" class="page-section">
      <div class="container">
        <div class="text-center align-content-sm-center">
          <h1>
            Here you can see some of my works
          </h1>
          <p>
            These are some of my works that I did for learning as well as at my job for company and for their
            clients.
          </p>
          <p style="font-size: 0.6em; font-style: italic; color: lime">Scroll to see more →</p>
        </div>
        <div class="row">
<!--          <div class="col-sm-4 text-center align-content-sm-center">-->
<!--            <h1>-->
<!--              Here you can see some of my works-->
<!--            </h1>-->
<!--            <p>-->
<!--              These are some of my works that I did for learning as well as at my job for company and for their-->
<!--              clients.-->
<!--            </p>-->
<!--            <p style="font-size: 0.6em; font-style: italic; color: lime">Scroll to see more →</p>-->
<!--          </div>-->
          <div class="col-sm">
            <div class="scrollable-horizontal d-flex">
              <div class="container-sm border border-white rounded-3 p-3 m-3" style="position: relative; min-width: 300px; min-height: 300px;" v-for="(card, index) in cards" :key="index" >
                <div class="row">
                  <img :src="card.image" class="card-img-top rounded-3"
                       style="max-width: 100%; height: 50%; aspect-ratio: auto;"
                       alt="{{card.alt}}">
                </div>
                <div class="row m-0 p-0">
                  <div class="card-body container-sm d-grid">
                    <div class="row">
                      <h5><br>{{ card.title }}</h5>
                    </div>
                    <div class="row">
                      <p style="text-wrap: wrap">{{ card.description }}</p>
                    </div>
                      <div v-for="(link, linkIndex) in card.links" :key="linkIndex" class="row g-2 align-items-sm-center">
                        <div v-if="link.ios" class="col-sm">
                          <div class="btn btn-dark"><a
                              class="text-light link-underline link-underline-opacity-0"
                              :href="link.ios"
                              target="_blank"><i class="bi bi-apple"></i> App Store</a></div>
                        </div>
                        <div v-if="link.play" class="col-sm">
                          <div class="btn btn-dark"><a
                              class="text-light link-underline link-underline-opacity-0"
                              :href="link.play"
                              target="_blank"><i class="bi bi-google-play"></i> Play Store</a>
                          </div>
                        </div>
                        <div v-if="link.git" class="col-sm align-self-center">
                          <div class="btn btn-dark"><a
                              class="text-light link-underline link-underline-opacity-0"
                              :href="link.git"
                              target="_blank"><i class="bi bi-github"></i> Github</a>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="ContactMe" class="page-section align-items-center">
<!--      <div class="container">-->
<!--        <div class="row border border-info">-->
<!--          <div class="col-4 justify-content-center align-content-center border border-secondary">-->
<!--            <img src="../assets/images/coffee.png" alt="Image" class="img-fluid">-->
<!--          </div>-->
<!--          <div class="col border border-primary">-->
<!--            <div class="container mt-5">-->
<!--              <h1 class="text-center mb-5">Feel free to contact me</h1>-->
<!--              <form @submit.prevent="sendEmail">-->
<!--                <div class="mb-3">-->
<!--                  <label for="name" class="form-label">Name</label>-->
<!--                  <input type="text" class="form-control" id="name" v-model="form.name" placeholder="Enter your name"-->
<!--                         required>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                  <label for="email" class="form-label">Email</label>-->
<!--                  <input type="email" class="form-control" id="email" v-model="form.email" placeholder="Enter your email"-->
<!--                         required>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                  <label for="subject" class="form-label">Subject</label>-->
<!--                  <input type="text" class="form-control" id="subject" v-model="form.subject"-->
<!--                         placeholder="Enter the subject" required>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                  <label for="message" class="form-label">Message</label>-->
<!--                  <textarea class="form-control" id="message" v-model="form.message" rows="3"-->
<!--                            placeholder="Enter your message" required></textarea>-->
<!--                </div>-->
<!--                <button type="submit" class="btn btn-primary">Send Message</button>-->
<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="container d-flex justify-content-between align-items-center align-content-between"
           data-bs-theme="dark">
<!--        <img src="../assets/images/coffee.png" alt="Image" class="img-fluid d-none d-md-block" style="max-width: 40%;">-->
<!--        <div class="w-50 border border-danger">-->
          <div class="container mt-5">

            <h1 class="text-center mb-5">Let's have a chat <i class="bi bi-cup-hot-fill"></i></h1>
            <Vue3Lottie :animationData="playJSON" :height="0" :width="0"/>
            <form @submit.prevent="sendEmail">
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" v-model="form.name" placeholder="Enter your name"
                       required>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" v-model="form.email" placeholder="Enter your email"
                       required>
              </div>
              <div class="mb-3">
                <label for="subject" class="form-label">Subject</label>
                <input type="text" class="form-control" id="subject" v-model="form.subject"
                       placeholder="Enter the subject" required>
              </div>
              <div class="mb-3">
                <label for="message" class="form-label">Message</label>
                <textarea class="form-control" id="message" v-model="form.message" rows="3"
                          placeholder="Enter your message" required></textarea>
              </div>
              <button type="submit" class="btn btn-primary">Send Message</button>
            </form>
          </div>
<!--        </div>-->
      </div>
    </section>
    <footer id="SocialMedia" class="bg-black text-light">
      <div class="bg-black text-center text-white">
        <div class="container p-4 pb-0">
          <section class="mb-4">
            <a class="btn btn-outline-light btn-floating m-1" href="https://twitter.com/Akshat_Soni_22" role="button" target="_blank">
              <i class="bi bi-twitter-x"></i>
            </a>
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/soniakshat22/" role="button" target="_blank">
              <i class="bi bi-instagram"></i>
            </a>
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/in/soni-akshat/" role="button" target="_blank">
              <i class="bi bi-linkedin"></i>
            </a>
            <a class="btn btn-outline-light btn-floating m-1" href="https://github.com/soniakshat" role="button" target="_blank">
              <i class="bi bi-github"></i>
            </a>
          </section>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6 justify-content-center">
              <ul class="list-inline align-left">
                <li class="list-inline-item mbr-fonts-style mbr-theme-style">
                  <p id="copyrightYear">Copyright &copy; Akshat Soni All Rights Reserved {{ getCurrentYear() }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import playJSON from "../assets/lottie.json";

import NavBar from '../components/NavBar.vue';
import placeholder from '../assets/images/placeholder.png'
import pb_logo from '../assets/images/partybus/logo.png'
import mlt_logo from '../assets/images/mlt/logo.png'
import td_logo from '../assets/images/td/logo.png'
import pcp_logo from '../assets/images/project cats paw/logo.png'

export default {
  name: 'HomeView',
  components: {
    Vue3Lottie,
    NavBar
  },
  data() {
    return {
      playJSON,
      cards: [
        {
          image: mlt_logo,
          alt: 'mlt_logo',
          title: 'Most Likely To',
          description: 'It\'s a multiplayer game developed in the Unity game engine and C# scripting. The multiplayer is made using Socket.io 3.',
          links: [
            {
              ios: 'https://apps.apple.com/az/app/most-likely-to-by-partybus/id1667759802',
            }
          ]
        },
        {
          image: pb_logo,
          alt: 'partybus_logo',
          title: 'Partybus',
          description: 'Supervised and helped in managing and developing the game.',
          links: [
            {
              ios: 'https://apps.apple.com/az/app/partybus-party-games/id1566547358',
              play: 'https://play.google.com/store/apps/details?id=com.Panja',
            }
          ]
        },
        {
          image: td_logo,
          alt: 'td_logo',
          title: 'Truth or Dare',
          description: 'Maintained and provided timely updates.',
          links: [
            {
              ios: 'https://apps.apple.com/az/app/truth-or-dare-by-partybus/id1625682712',
              play: 'https://play.google.com/store/apps/details?id=com.truthordarebypartybus',
            }
          ]
        },
        {
          image: pcp_logo,
          alt: 'project_cats_paw_logo',
          title: 'Project CATS PAW',
          description: 'My very first game. I made this game for my bachelor\'s final semester submission',
          links: [
            {
              play: 'https://play.google.com/store/apps/details?id=com.TechCure.ProjectCATsPAW',
              git: 'https://github.com/soniakshat/Project-CATs-PAW'
            }
          ]
        },
        {
          image: placeholder,
          alt: 'ball_balance_logo',
          title: 'Ball Balance',
          description: 'My very first learning game. I made this game when I was learning Unity. This is a very simple game. Player needs to balance a ball with a ping pong bat.',
          links: [
            {
              // https://github.com/soniakshat/Project-CATs-PAW
              git: 'https://github.com/soniakshat/ball-balance'
            }
          ]
        },
      ],
      test: [
        {
          name: '1',
          desc: 'XTZ',
          links: [
              {
                play: 'xyz',
                ios: 'pqr',
                git: 'abc'
              },
          ]
        },
        {
          name: '2',
          desc: 'XTZ2',
          links: [
            {
              play: '',
              ios: 'pqr2',
              git: 'abc2'
            },
          ]
        },
        {
          name: '3',
          desc: 'XTZ3',
          links: [
            {
              play: 'xyz3',
              ios: '',
              git: 'abc3'
            },
          ]
        },
        {
          name: '4',
          desc: 'XTZ4',
          links: [
            {
              play: 'xyz4',
              ios: 'pqr4',
              git: ''
            },
          ]
        },
      ],
      form: {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    };
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    sendEmail() {
      // Implement email sending logic here
      let { name, email, subject, message } = this.form;

      window.location.href = `mailto:soni1b@uwindsor.ca?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message + "\n\n\nFrom: " + name + "\nEmail: " + email)}`;
      // alert('Email sent!');
    },
  },
};
</script>

<style scoped>
.rounded-circle {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.card-body .card-title {
  font-size: 2rem;
}
</style>
